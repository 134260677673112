import { graphql, useStaticQuery, Link } from "gatsby";
import Layout from "../components/layout";
import css from "@emotion/css";

const Blog = () => {
  const data = useStaticQuery(graphql`
    {
      allMarkdownRemark(
        sort: { order: DESC, fields: [frontmatter___date] }
        limit: 1000
      ) {
        edges {
          node {
            frontmatter {
              path
              title
              date
            }
          }
        }
      }
    }
  `);

  return (
    <Layout>
      {data.allMarkdownRemark.edges.map(e => (
        <div
          css={css`
            margin-bottom: 16px;
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;
          `}
        >
          <Link to={e.node.frontmatter.path}>{e.node.frontmatter.title}</Link>
          <div
            css={css`
              font-size: 80%;
            `}
          >
            {e.node.frontmatter.date}
          </div>
        </div>
      ))}
    </Layout>
  );
};

export default Blog;
